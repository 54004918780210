import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Collaborations = ({ collaborations }) => (
  <div className='px-8 py-10'>
    <div className='flex flex-col items-center gap-8'>
      <h2 className='md:text-xl text-3xl font-bold uppercase'>
        {collaborations.title}
      </h2>
      <div className='flex flex-wrap justify-center gap-6'>
        {collaborations.collabs.map((company, i) => (
          <div key={i} className='w-36 p-2'>
            <GatsbyImage
              image={company.image.childImageSharp.gatsbyImageData}
              alt={`${company.name} logo`}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Collaborations;
