import React from "react";

const HeaderVideo = ({ videoFile }) => {
  return (
    <video
      preload='auto'
      playsInline
      autoPlay
      muted
      loop
      className='object-cover h-full'
    >
      <source src={videoFile} type='video/mp4' />
    </video>
  );
};

export default HeaderVideo;
