import React from "react";

import Services from "../Services/Services";
import HeaderTitles from "./HeaderTitles";
import HeaderVideo from "./HeaderVideo";

const Header = ({ header, videoFile, services }) => {
  return (
    <div className='md:pt-11 h-max relative pt-20'>
      <HeaderTitles titles={header} />

      <div className='h-96 md:h-[600px] lg:h-fit'>
        <HeaderVideo videoFile={videoFile} />
      </div>

      <div className='md:absolute md:bottom-0 md:z-10'>
        <Services services={services} />
      </div>
    </div>
  );
};
export default Header;
