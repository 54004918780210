import React from "react";

const HeaderTitles = ({ titles }) => {
  const titleShadow = { textShadow: "3px 0px 7px rgba(67,67,67,0.7)" };

  return (
    <div className='md:h-2/3 lg:h-4/5 h-96 pb-28 absolute z-10 flex flex-col items-center justify-center w-full gap-3 text-white'>
      <div
        className='lg:text-6xl md:text-5xl md:gap-2 mt-14 md:mt-10 flex flex-col justify-center gap-0 text-4xl font-bold text-center uppercase'
        style={titleShadow}
      >
        <h1>{titles.title1}</h1>
        <h1>{titles.title2}</h1>
      </div>
      <h2
        style={titleShadow}
        className='md:text-xl text-sm text-center text-white'
      >
        {titles.subtitle}
      </h2>
    </div>
  );
};

export default HeaderTitles;
