import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

const ServicesButtons = ({ services }) => {
  return (
    <div className='md:grid-cols-3 md:gap-20 grid grid-cols-1 gap-10 mb-3'>
      {services &&
        services.map((service, i) => (
          <div
            key={i}
            className='md:px-0 flex flex-col items-center gap-4 px-6'
          >
            <Link to={service.link} className='w-full'>
              <div
                className={`
                      hover:bg-${service.link.slice(
                        1
                      )} hover:border-${service.link.slice(
                  1
                )} service-navigation-button`}
              >
                {service.label}
              </div>
            </Link>
            <div className='text-sm'>{service.description}</div>
          </div>
        ))}
    </div>
  );
};

export default ServicesButtons;
