import React from "react";
import ServicesButtons from "./ServicesButtons";

const Services = ({ services }) => {
  return (
    <div className='bg-neutral-100/70' id='services'>
      <div className='lg:px-40 sm:px-10 xl:px-72 lg:py-6 md:py-2 md:gap-6 flex flex-col items-center gap-6 px-8 py-6'>
        {services.servicesTitle && (
          <div className='sm:text-2xl w-full text-xl font-bold text-center uppercase'>
            {services.servicesTitle}
          </div>
        )}
        <ServicesButtons services={services.services} />
      </div>
    </div>
  );
};
export default Services;
