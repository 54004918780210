import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import Header from "../components/Index/Header/Header";
import Collaborations from "../components/Index/Collaborations/Collaborations";

import videoFile from "/src/images/Index/cadt.mp4";

const Index = (props) => {
  const { t } = useTranslation();

  const data = {
    header: {
      title1: t("TR_INDEX_HEADER__TITLE1"),
      title2: t("TR_INDEX_HEADER__TITLE2"),
      subtitle: t("TR_INDEX_HEADER__SUBTITLE"),
    },
    services: {
      servicesTitle: t("TR_INDEX_SERVICES_TITLE"),
      services: [
        {
          label: t("TR_MENU_SOFTWARE"),
          link: "/software",
          description: t("TR_INDEX_SERVICES_SOFTWARE_DESCRIPTION"),
        },
        {
          label: t("TR_MENU_DESIGN"),
          link: "/design",
          description: t("TR_INDEX_SERVICES_DESIGN_DESCRIPTION"),
        },
        {
          label: t("TR_MENU_ACADEMY"),
          link: "/academy",
          description: t("TR_INDEX_SERVICES_ACADEMY_DESCRIPTION"),
        },
      ],
    },
    collaborations: {
      title: t("TR_INDEX_COLLABORATIONS_TITLE"),
      collabs: props.data.collabData.nodes.map((c) => c.frontmatter),
    },
  };

  return (
    <div>
      <div className='relative'>
        <Layout>
          {data && (
            <>
              <Header
                header={data.header}
                videoFile={videoFile}
                services={data.services}
              />
              <Collaborations collaborations={data.collaborations} />
            </>
          )}
        </Layout>
      </div>
    </div>
  );
};

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    collabData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/collaborations/i" } }
    ) {
      nodes {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          name
        }
      }
    }
  }
`;
export const Head = () => (
  <>
    <title>CADT</title>
  </>
);
